
.certificate-management {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;

    .certificate-item {
        display: none;
        height: 1%;
        flex: 1;
        flex-direction: column;

        &.current {
            display: flex;
        }

        .add-template {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
        }

        .customTable {
            width: 100%;
            flex: 1;
            margin-top: 20px;
        }

        .filter-box {
            .question-class {
                display: flex;

                .question-item {
                    display: flex;
                    align-items: center;

                    &:last-child {
                        padding-left: 48px;
                    }

                    .w300-cascader {
                        width: 300px;
                    }

                    .W200-select {
                        width: 200px;
                    }

                    .text {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}
